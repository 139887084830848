import React, { useEffect } from "react"
import { Link, graphql } from "gatsby"

import { GatsbyImage } from "gatsby-plugin-image"
import { getSrc } from "gatsby-plugin-image"

import Moment from "react-moment"

import Layout from "../components/layout"
import Seo from "../components/seo"
import VerticalTimeLine from "../components/vertical-timeline"

export const querymp = graphql`
  query pageQueryAndMainPageQuery($slug: String!) {
    strapiPage(slug: { eq: $slug }) {
      slug
      Hero {
        localFile {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              quality: 80
              breakpoints: [768, 1024, 1280]
            )
          }
        }
      }
      Description
      Content
      Title
      modal {
        id
        title
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                width: 768
                height: 768
                quality: 50
                breakpoints: [768, 1024, 1280]
                layout: CONSTRAINED
              )
            }
          }
        }
        modalSubTitle
        modalContent
        modalButtonText
        modalButtonSecondText
        tripDate
      }
      verticalTimeline {
        id
        eventTime
        eventTitle
        eventDescription
      }
    }
    strapiMainPage(pages: { elemMatch: { slug: { eq: $slug } } }) {
      slug
      pages {
        id
        Title
        slug
      }
    }
  }
`

const Page = ({ data }) => {
  const page = data.strapiPage
  const main_page = data.strapiMainPage
  const modals = data.strapiPage.modal
  const events = data.strapiPage.verticalTimeline

  function markupPageContent() {
    return { __html: page.Content }
  }

  useEffect(() => {
    const script = document.createElement("script")

    script.src = "/js/responsive-iframe.js"
    script.async = true

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  useEffect(() => {
    let modalWindow = document.createElement("script")
    modalWindow.src = "/js/_1_modal-window.js" // 👈 make sure to use the correct path
    modalWindow.id = "modal-window"
    document.body.appendChild(modalWindow)

    return () => {
      document.body.removeChild(modalWindow)
    }
  }, [])

  return (
    <Layout>
      <Seo
        title={page.Title + " | Vacational Studies"}
        description={page.Description}
      />

      <div id="sub-header" className="bg-contrast-lower">
        <div className="container max-width-adaptive-lg">
          <div className="subnav subnav--expanded@sm js-subnav">
            <button className="reset btn btn--subtle margin-y-sm subnav__control js-subnav__control">
              <span>Show Submenu</span>
              <svg
                className="icon icon--xxs margin-left-xxs"
                aria-hidden="true"
                viewBox="0 0 12 12"
              >
                <polyline
                  points="0.5 3.5 6 9.5 11.5 3.5"
                  fill="none"
                  strokeWidth="1"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></polyline>
              </svg>
            </button>

            <div className="subnav__wrapper js-subnav__wrapper">
              <nav className="subnav__nav">
                <button
                  className="reset subnav__close-btn js-subnav__close-btn js-tab-focus"
                  aria-label="Close navigation"
                >
                  <svg className="icon" viewBox="0 0 16 16">
                    <g
                      strokeWidth="1"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeMiterlimit="10"
                    >
                      <line x1="13.5" y1="2.5" x2="2.5" y2="13.5"></line>
                      <line x1="2.5" y1="2.5" x2="13.5" y2="13.5"></line>
                    </g>
                  </svg>
                </button>

                <ul className="subnav__list">
                  {main_page.pages
                    .sort(
                      ({ id: previousID }, { id: currentID }) =>
                        previousID - currentID
                    )
                    .map((page, i) => {
                      return (
                        <li className="subnav__item" key={`page__${page.slug}`}>
                          <Link
                            className="subnav__link"
                            to={`/${main_page.slug}/${page.slug}`}
                          >
                            {page.Title}
                          </Link>
                        </li>
                      )
                    })}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <div id="hero" className="container max-width-adaptive-lg margin-top-sm">
        <section
          className="bg-cover bg-center bg-no-repeat"
          style={{
            backgroundImage: `url(${getSrc(
              page.Hero.localFile.childImageSharp.gatsbyImageData
            )})`,
          }}
        >
          <div className="padding-y-xxxl"></div>
        </section>
      </div>

      <div id="content">
        <div className="container max-width-adaptive-sm padding-top-xl padding-bottom-xxl position-relative z-index-1">
          <article className="article text-component padding-bottom-lg">
            <h1 className="text-xxxl">{page.Title}</h1>
            <p className="text-md color-contrast-medium">{page.Description}</p>
            <div dangerouslySetInnerHTML={markupPageContent()} />
          </article>

          {/* Component - A day in the life */}
          {events.length > 0 && <VerticalTimeLine events={events} />}

          {/* Component - Trip Modal Block */}
          {modals.length > 0 && (
            <div className="grid gap-sm">
              {modals
                .sort(function (a, b) {
                  return new Date(a.tripDate) - new Date(b.tripDate)
                })
                .map((modal, i) => {
                  let date
                  if (modal.tripDate !== null) {
                    date = (
                      <div className="modal-badge">
                        <span className="text-lg line-height-xs block">
                          <Moment format="D">{modal.tripDate}</Moment>
                        </span>
                        <span className="line-height-xs block text-uppercase">
                          <Moment format="MMM">{modal.tripDate}</Moment>
                        </span>
                      </div>
                    )
                  } else {
                    date = (
                      <div className="modal-badge">
                        <span className="text-lg line-height-xs block">
                          TBC
                        </span>
                      </div>
                    )
                  }

                  return (
                    <div
                      key={`modal__block__${modal.id}`}
                      className="card-v2 col-4@md cursor-pointer"
                      aria-labelledby="card-title-5"
                      aria-controls={`modal-name-${modal.id}`}
                    >
                      {date}
                      <figure className="object-cover height-100%">
                        <GatsbyImage
                          image={{
                            ...modal.image.localFile.childImageSharp
                              .gatsbyImageData,
                            aspectRatio: 1 / 1,
                          }}
                          alt={modal.title}
                        />
                        <figcaption className="card-v2__caption padding-sm text-center">
                          <div className="text-sm">{modal.title}</div>
                        </figcaption>
                      </figure>
                    </div>
                  )
                })}
            </div>
          )}
        </div>
      </div>

      {modals.length > 0 && (
        <div id="modals">
          {modals.map((modal, i) => {
            function markupModalContent() {
              return { __html: modal.modalContent }
            }

            return (
              <div
                key={`modal__${modal.id}`}
                className="modal modal--animate-translate-up flex flex-center bg-contrast-higher bg-opacity-90% padding-md js-modal"
                id={`modal-name-${modal.id}`}
              >
                <div
                  className="modal__content width-100% max-width-sm bg shadow-md flex flex-column"
                  role="alertdialog"
                  aria-labelledby={`modal-title-${modal.id}`}
                  aria-describedby={`modal-description-${modal.id}`}
                >
                  <header className="bg-contrast-lower padding-y-sm padding-x-md flex items-center justify-between flex-shrink-0">
                    <div>
                      <h4
                        className="text-truncate"
                        id={`modal-title-${modal.id}`}
                      >
                        {modal.title}
                      </h4>
                      <p>{modal.modalSubTitle}</p>
                    </div>

                    <button className="reset modal__close-btn modal__close-btn--inner js-modal__close js-tab-focus">
                      <svg className="icon" viewBox="0 0 20 20">
                        <title>Close modal window</title>
                        <g
                          fill="none"
                          stroke="currentColor"
                          strokeMiterlimit="10"
                          strokeWidth="2"
                        >
                          <line x1="3" y1="3" x2="17" y2="17" />
                          <line x1="17" y1="3" x2="3" y2="17" />
                        </g>
                      </svg>
                    </button>
                  </header>

                  <div className="padding-y-sm padding-x-md flex-grow overflow-auto momentum-scrolling">
                    <div className="text-component">
                      <div dangerouslySetInnerHTML={markupModalContent()} />
                    </div>
                  </div>

                  <footer className="padding-y-sm padding-x-md bg shadow-md flex-shrink-0">
                    <div className="flex justify-end gap-xs">
                      <Link
                        className="btn btn--subtle text-decoration-none"
                        to={`/apply-now`}
                      >
                        {modal.modalButtonText}
                      </Link>
                      <Link
                        className="btn btn--primary text-decoration-none"
                        to={`/get-in-touch`}
                      >
                        {modal.modalButtonSecondText}
                      </Link>
                    </div>
                  </footer>
                </div>
              </div>
            )
          })}
        </div>
      )}
    </Layout>
  )
}

export default Page
