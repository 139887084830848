import React, { useEffect } from "react"

const VerticalTimeline = ({ events }) => {
  useEffect(() => {
    let verticalTimeline = document.createElement("script")
    verticalTimeline.src = "/js/_1_vertical-timeline.js" // 👈 make sure to use the correct path
    verticalTimeline.id = "vertical-timeline"
    document.body.appendChild(verticalTimeline)

    return () => {
      document.body.removeChild(verticalTimeline)
    }
  }, [])

  return (
    <div className="v-timeline js-v-timeline" data-animation="on">
      {events.map((event, i) => {
        function markupEventDescription() {
          return { __html: event.eventDescription }
        }
        return (
          <section
            className="v-timeline__section js-v-timeline__section"
            key={`${event.eventTitle}`}
          >
            <div
              className="v-timeline__marker bg-contrast-high border border-3 border-contrast-lower"
              aria-hidden="true"
            ></div>

            <div className="v-timeline__items-group">
              <div className="v-timeline__item bg padding-md radius-md shadow-md">
                <div className="v-timeline__date margin-bottom-sm">
                  <span className="v-timeline__date-value">
                    {event.eventTime}
                  </span>
                </div>

                <div className="text-component">
                  <h2 className="text-lg">{event.eventTitle}</h2>
                  {event.eventDescription !== null && (
                    <div dangerouslySetInnerHTML={markupEventDescription()} />
                  )}
                </div>
              </div>
            </div>
          </section>
        )
      })}
    </div>
  )
}

export default VerticalTimeline
